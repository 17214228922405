<template>
  <div>
    <div class="commissions-signs__container">
      <!-- DESKTOP -->
      <v-row>
        <v-col class="pl-12 pr-12 text-left" cols="12">
          <h1 class="commissions-sign__title">Acta de comisiones</h1>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-sm-margin-sign">
        <v-col
          style="text-align: left"
          cols="12"
          class="pb-4 px-0 index-f-sm mb-sm-margin-sign"
        >
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm-sign txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm-sign">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm-sign mb-1 ml-9">
            para firmar el documento
          </p>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="pendingDocument" class="mx-0">
        <v-col cols="12" md="10" xs="10" sm="10" lg="7" xl="7" class="px-8">
          <p class="text-info-1 text-left">
            <strong> Estado del documento </strong>
          </p>
          <p class="text-info-1 text-left">
            El documento ya fue firmado, si no presenta una correcta
            visualización del documento por favor haga clic
            <v-btn
              @click="download"
              class="pl-5 pr-5 mr-2 ml-2 commission-sign__button"
              small
              rounded
              color="#466be3"
              dark
            >
              aquí
            </v-btn>
          </p>
        </v-col>
      </v-row>

      <v-row v-if="!pendingDocument" justify="center" class="mx-0">
        <v-col
          cols="12"
          md="10"
          xs="10"
          sm="10"
          lg="7"
          xl="7"
          class="px-8 pb-11"
        >
          <p class="text-info-1 text-left">
            <strong> Pasos para la firma del documento: </strong>
          </p>
          <p class="text-info-1 text-left">
            <strong> 1. </strong> Lea con atención el siguiente documento.
          </p>
          <p class="text-info-1 text-left">
            <strong> 2. </strong> En la parte inferior de esta página firme el
            documento en el <strong>RECUADRO AZUL</strong> o seleccione la firma
            previamente registrada.
          </p>
          <p class="text-info-1 text-left">
            <strong> 3. </strong> Si no presenta una correcta visualización del
            documento por favor haga clic
            <v-btn
              @click="download"
              class="pl-5 pr-5 mr-2 ml-2 commission-sign__button"
              small
              rounded
              color="#466be3"
              dark
            >
              <strong> aquí </strong>
            </v-btn>
            se generará una nueva ventana en el navegador o se descarga en su
            dispositivo. Una vez finalice la lectura, vuelva a esta página y
            firme en el <strong>RECUADRO AZUL</strong>.
          </p>
          <p class="text-info-1 text-left">
            <strong> 4. </strong> Preferiblemente genere la firma del documento
            desde un dispositivo móvil para una mayor facilidad en su firma.
          </p>
          <p class="text-info-1 text-left">
            <strong> 5. </strong> Finalmente de clic en guardar, para registrar
            la firma.
          </p>
          <!-- <p class="text-info-1 text-left">
            <strong> NOTA: </strong> Por favor tenga en cuenta que cuando firme
            el documento de condición de comisiones, significa que esta
            aceptando todos los términos y automaticamente se firmará en cada
            hoja en señal de aprobación.
          </p> -->
          <p class="text-info-1 text-left">
            <strong> NOTA: </strong> Por favor tenga en cuenta que cuando firme
            el documento de condición de comisiones, significa que esta
            aceptando todos los términos y automaticamente se firmará en cada
            hoja en señal de aprobación. Al momento de finalizar se enviará un
            correo electrónico al mail registrado en Mr Chispa con la copia del
            documento, la firma quedará registrada para una próximo documento.
          </p>
        </v-col>
      </v-row>

      <div class="app-content">
        <vue-pdf-embed
          ref="pdfRef"
          :source="pdfSource"
          :page="page"
          @rendered="handleDocumentRender"
        />
      </div>

      <v-row justify="center" v-if="!pendingDocument">
        <transition name="slide-fade">
          <v-card
            elevation="0"
            class="commissions-sign__card pa-6 slide-bottom"
            outlined
            v-if="validateTerms"
          >
            <v-card-title class="pb-0 px-0">
              <v-checkbox
                v-model="disabledCheck"
                :label="'Acepto las condiciones consignadas en el documento relacionado anteriormente'"
                color="#3957B9"
                class="mt-0 px-0 commissions-sign__check"
              ></v-checkbox>
            </v-card-title>
            <v-card-actions class="px-0">
              <v-btn
                @click="validateTerms = false"
                class="pl-5 pr-5 mr-2 ml-2 commission-sign__button"
                rounded
                style="color: #fff"
                color="#466BE3"
                :disabled="!disabledCheck"
                block
              >
                <strong> Firmar </strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </transition>
      </v-row>

      <template v-if="!validateTerms">
        <v-row v-if="!pendingDocument" justify="center" class="mx-0">
          <v-col
            cols="12"
            md="10"
            xs="10"
            sm="10"
            lg="7"
            xl="7"
            class="px-10 mx-0"
          >
            <p class="text-info-1 text-left">
              <strong> Seleccione firma: </strong>
              <v-btn v-if="!showSign" x-small outlined @click="showSign = true">
                Generar nueva firma
              </v-btn>
            </p>
            <v-alert v-if="showAlert" dense text type="info" class="mt-3">
              No se encontró una firma, por favor genere una nueva en el
              <strong>RECUADRO AZUL</strong>
            </v-alert>
            <img
              style="width: 100%"
              :src="imgSign.signature"
              aalt="Firma del colaborador"
            />
          </v-col>
        </v-row>
        <template v-if="showSign && !pendingDocument">
          <v-row justify="center" class="mx-0">
            <v-col
              class="pb-0 mx-0"
              cols="10"
              md="3"
              xs="3"
              sm="3"
              lg="3"
              xl="3"
            >
              <v-btn block outlined small color="#3957B9" @click="undo"
                >Deshacer</v-btn
              >
            </v-col>
            <v-col
              class="pb-0 mx-0"
              cols="10"
              md="3"
              xs="3"
              sm="3"
              lg="3"
              xl="3"
            >
              <v-btn outlined color="#3957B9" block small @click="clear"
                >limpiar</v-btn
              >
            </v-col>
          </v-row>
          <v-row justify="center" class="mx-0">
            <v-col class="pt-3 mx-0" md="10" xs="10" sm="10" lg="6" xl="6">
              <vueSignature
                class="sign"
                ref="signature"
                :sigOption="option"
                :h="'150px'"
                :disabled="disabled"
              ></vueSignature>
            </v-col>
          </v-row>
        </template>
        <v-row
          v-if="!pendingDocument"
          justify="center"
          class="mb-15 mx-0 pb-10"
        >
          <v-col cols="12" md="10" xs="10" sm="10" lg="6" xl="6">
            <v-layout justify-end="">
              <v-btn
                :loading="loading"
                class="commission-sign__button"
                block=""
                @click="sign()"
                rounded
                color="#466be3"
                dark
              >
                Guardar
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </template>
    </div>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialog.sign"
      persistent
      max-width="490"
      content-class="scroll-y-sign"
    >
      <v-card>
        <v-card-title class="text-h5 request-project-sign">
          <v-layout justify-center> GUARDAR FIRMA </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project-sign">
          Desea guardar la firma para una próxima ocasión
        </v-card-text>
        <v-card-actions>
          <v-row class="commissions-sign__saveSign">
            <v-col cols="12" md="6">
              <v-btn rounded outlined block @click="close()"
                >No registrar firma</v-btn
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-btn rounded outlined block @click="saveSign()"
                >Registrar firma</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="message.dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="request-project-sign">
          <v-layout justify-center>
            {{ message.title }}
          </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project-sign">
          {{ message.sms }}
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
  </div>
</template>
<script>
import vueSignature from "vue-signature";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  name: "app",
  components: {
    vueSignature,
    VuePdfEmbed,
    Alert,
  },
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loadingAll: true,
      loading: false,
      page: null,
      pageCount: 1,
      pdfSource: "",
      showAllPages: true,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
        fontWeight: 0,
      },
      dialog: {
        sign: false,
      },
      disabled: false,
      infoToken: {
        document: "",
      },
      name: "",
      token: "",
      showAlert: false,
      newSign: false,
      imgSign: false,
      showSign: false,
      pendingDocument: true,
      name: "Angie",
      disabledCheck: false,
      validateTerms: true,
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    download() {
      const link = document.createElement("a");
      link.href = this.pdfSource;
      link.target = "_blank";
      link.download =
        "acta-comisiones" + new Date().toISOString().substr(0, 10) + ".pdf";
      link.click();
    },
    handleDocumentRender() {
      // this.loadingAll = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getCommissionsImg() {
      this.loadingAll = true;
      Api.MarginCommisions()
        .getSign(this.token)
        .then((res) => {
          this.loadingAll = false;
          this.imgSign = res.data.data;
          if (this.imgSign != null) {
            this.showSign = false;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          if (error.response.status == 404) {
            this.showSign = true;
            this.showAlert = true;
          }
        });
    },
    getPdf() {
      this.loadingAll = true;
      Api.MarginCommisions()
        .getDocumentSign(this.token) // este no es
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            if (res.data.data.signed) {
              this.pendingDocument = true;
              this.pdfSource = res.data.data.document;
            } else {
              this.pendingDocument = false;
              this.pdfSource =
                res.data.data.commission_condition.commission_conditions;
            }
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          this.message.dialog = true;
          this.message.title = "¡Oh no!";
          this.message.sms = error.response.data.message;
          this.message.type = "error";
          this.message.redirect = "marginCommisions";
          // setTimeout(() => {
          //   this.$router.push({ name: "marginCommisions" });
          // }, 3000);
        });
    },
    sign() {
      this.loadingAll = true;
      var _this = this;
      if (_this.$refs.signature && !_this.$refs.signature1.isEmpty()) {
        var png = _this.$refs.signature.save("image/png");
        fetch(png)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "firma.png", { type: "image/png" });
            const formData = new FormData();
            formData.append("signature", file);
            // this.loadingAll = true;
            Api.MarginCommisions()
              .sign(formData, this.token)
              .then((res) => {
                this.loadingAll = false;
                if (res.data.cod == 0) {
                  this.dialog.sign = true;
                }
              })
              .catch((error) => {
                this.loadingAll = false;
                console.log(error);
              });
          });
      } else {
        Api.MarginCommisions()
          .sign(null, this.token)
          .then((res) => {
            this.loadingAll = false;
            this.message.dialog = true;
            this.message.title = "Excelente";
            this.message.sms = "Se registró la firma correctamente";
            this.message.type = "success";
            this.message.redirect = "marginCommisions";
            // setTimeout(() => {
            //   this.$router.push({ name: "marginCommisions" });
            // }, 5000);
            // this.$router.push({ name: "marginCommisions" });
            // if (res.data.cod == 0) {
            //   this.dialog.sign = true;
            // }
          })
          .catch((error) => {
            this.loadingAll = false;
            console.log(error);
          });
      }
    },
    close() {
      this.message.dialog = true;
      this.message.title = "Excelente";
      this.message.sms = "Se registró la firma correctamente";
      this.message.type = "success";
      this.message.redirect = "marginCommisions";
      // setTimeout(() => {
      //   this.$router.push({ name: "marginCommisions" });
      // }, 5000);
    },
    saveSign() {
      var _this = this;
      var png = _this.$refs.signature.save("image/png");
      fetch(png)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "firma.png", { type: "image/png" });
          const formData = new FormData();
          formData.append("signature", file);
          this.loadingAll = true;
          Api.MarginCommisions()
            .saveSign(formData, this.token)
            .then((res) => {
              this.loadingAll = false;

              this.message.dialog = true;
              this.message.title = "Excelente";
              this.message.sms = "Se registró la firma correctamente";
              this.message.type = "success";
              this.message.redirect = "marginCommisions";
              // setTimeout(() => {
              //   this.$router.push({ name: "marginCommisions" });
              // }, 5000);

              // this.$router.push({ name: "marginCommisions" });
            })
            .catch((error) => {
              this.loadingAll = false;
              console.log(error);
            });
        });
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },

    fromDataURL(url) {
      // NO SE
      var _this = this;
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.getCommissionsImg();
    this.getPdf();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.scroll-y-sign {
  box-shadow: none !important;
  overflow-y: inherit !important;
}

.mb-xl-margin-sign {
  display: block;
}

.mb-sm-margin-sign {
  margin-top: 32px;
  display: none;
}

.sign {
  border: 1.3px solid;
  border-color: #3a57b9;
}
.request-project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.request-txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.text-apps-blue-sm-sign {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm-sign {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.commission-sign__button:hover {
  background-color: #324ca1 !important;
}

.commissions-sign__check {
  font-family: "RobotoRegular";
}

.commissions-sign__card {
  font-family: "RobotoRegular";
  border-radius: 15px !important;
  position: fixed;
  bottom: 20px;
  z-index: 100;
}

.commissions-sign__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.commissions-signs__container {
  margin-top: 56px;
}

.commissions-sign__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .commissions-sign__card {
    margin-bottom: 56px;
  }

  .commissions-signs__container {
    margin-top: 0;
  }

  .commissions-sign__title {
    display: none;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-margin-sign {
    display: none;
  }
  .mb-sm-margin-sign {
    margin-top: 20px;
    margin-left: 12px;
    display: block;
    text-align: initial;
  }

  .commissions-sign__saveSign {
    flex-direction: column;
    align-items: center;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-margin-sign {
    display: none;
  }
  .mb-sm-margin-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-margin-sign {
    display: none;
  }
  .mb-sm-margin-sign {
    display: block;
    text-align: initial;
  }
}
</style>
